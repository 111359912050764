import React, { useEffect, useState } from 'react';
import {
    Container, Typography, Grid, Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination,
    TextField, IconButton, CircularProgress, Snackbar, Alert, Backdrop
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LoginIcon from '@mui/icons-material/Login';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import HeaderComponent from '../components/HeaderComponent';
import './view.css';

// Función para formatear la fecha
const formatDateSesion = (isoDateString) => {
    const date = new Date(isoDateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
};

const DataTableT = ({ data }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '50%' }}>Usuario</TableCell>
                        <TableCell style={{ width: '50%' }}>Fecha de inicio de sesión</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => (
                        <TableRow key={item.id_histCarga}>
                            <TableCell style={{ width: '50%' }}>{item.userAudit}</TableCell>
                            <TableCell style={{ width: '50%' }}>{formatDateSesion(item.fechaCarga)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                onRowsPerPageChange={(e) => {
                    setRowsPerPage(parseInt(e.target.value, 10));
                    setPage(0);
                }}
                labelRowsPerPage="Filas por página:"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                selectProps={{
                    native: true,
                }}
            />
        </TableContainer>
    );
};

// Vista
const GestionLoginView = ({ model, controller }) => {
    useEffect(() => {        
        if (!model.dataDate) {
        controller.handleHistCarga();
        }
        if (!model.fechaInicio && !model.fechaFin) {
            controller.handleLstSesion();
        }
    }, [model.fechaInicio, model.fechaFin, controller]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}> {/* Usa AdapterDateFns */}
            <Container>
                <HeaderComponent title="Gestión de Sesión" controller={controller} />
                <Grid container spacing={2}>
                    {/* Campos de filtro */}
                    <Grid container item xs={12} sm={12} md={12} lg={12}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <DatePicker
                                label="Fecha inicio"
                                value={model.fechaInicio ? new Date(`${model.fechaInicio}T00:00:00`) : null}
                                onChange={controller.handleFechIniChange}
                                renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <DatePicker
                                label="Fecha fin"
                                value={model.fechaFin ? new Date(`${model.fechaFin}T00:00:00`) : null}
                                onChange={controller.handleFechFinChange}
                                renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} style={{ display: 'flex' }}>
                                <IconButton onClick={controller.handleLstSesion} style={{ borderRadius: '50%', color: 'white', background: '#031560', width: '48px', height: '48px',margin:'1vh' }}>
                                    <SearchIcon />
                                </IconButton>
                                <IconButton onClick={controller.handleClearFiltersSesion} style={{ borderRadius: '50%', color: 'white', background: '#031560', width: '48px', height: '48px',margin:'1vh' }}>
                                    <HighlightOffIcon />
                                </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                    </Grid>
                    <Snackbar open={model.snackbarOpen} autoHideDuration={6000} onClose={controller.handleCloseSnackbar}>
                        <Alert onClose={controller.handleCloseSnackbar} severity={model.snackbarSeverity} sx={{ width: '100%' }}>
                            {model.snackbarMessage}
                        </Alert>
                    </Snackbar>
                    <Backdrop open={model.importing} style={{ zIndex: 1300, color: '#fff' }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Grid>
                {/* Tabla de datos */}
                <DataTableT data={model.dataSesion} />
                {model.dataDate && model.dataDate.length > 0 && (
                    <Grid item xs={12} sm={2} md={2} lg={3} style={{ textAlign: 'right', marginTop: '5px', color: '#031560' }}>
                        <Typography variant="h6">Fecha de Carga: {formatDate(model.dataDate[0].fechaCarga)}</Typography>
                    </Grid>
                )}
            </Container>
        </LocalizationProvider>
    );
};

export default GestionLoginView;
