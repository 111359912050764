import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'; // Asegúrate de importar correctamente
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Model from './Model/model';
import Controller from './Controller/controller';
import LoginView from './View/LoginView';
import ConsultaStockView from './View/ConsultaStockView';
import ConsultaRespuestos from './View/ConsultaRespuestos';
import GestionUsuariosView from './View/GestionUsuariosView';
import GestionLoginView from './View/GestionLoginView';
import ManagerKitRespuestos from './View/ManagerKitRespuestos';
import NavMenu from './components/NavMenu';

const App = () => {
  const model = Model();
  const navigate = useNavigate();
  const controller = Controller({ model, navigate });

  const [userId, setUserId] = React.useState(parseInt(localStorage.getItem('userId')));
  const [userRole, setUserRole] = React.useState(parseInt(localStorage.getItem('profile')));

  useEffect(() => {
    const storedUserId = parseInt(localStorage.getItem('userId'));
    const storedUserRole = parseInt(localStorage.getItem('profile'));
    setUserId(storedUserId);
    setUserRole(storedUserRole);
  }, [model]);

  if (!userId || userId === 0) {
    return <LoginView model={model} controller={controller} />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Grid item xs={2} sx={{ backgroundColor: '#031560', height: '100vh' }}>
        <NavMenu userRole={userRole} />
      </Grid>
      <Grid item xs={10} sx={{ p: 3 }}>
        <Routes>
          {userRole === 3 && <Route path="/" element={<Navigate to="/consulta-repuesto" />} />}
          {(userRole === 1 || userRole === 2 || userRole === 4) && <Route path="/" element={<Navigate to="/consulta-stock" />} />}
          <Route path="/consulta-stock" element={<ConsultaStockView model={model} controller={controller} />} />
          <Route path="/consulta-repuesto" element={<ConsultaRespuestos model={model} controller={controller} />} />
          <Route path="/gestion-usuarios" element={<GestionUsuariosView model={model} controller={controller} />} />
          <Route path="/gestion-login" element={<GestionLoginView model={model} controller={controller} />} />
          <Route path="/kit-repuesto" element={<ManagerKitRespuestos model={model} controller={controller} />} />
        </Routes>
      </Grid>
    </Box>
  );
};

export default App;
