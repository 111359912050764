import * as XLSX from 'xlsx';
import { API_URL } from '../Const/const';
import axios from 'axios';

const Controller = ({ model, navigate }) => {
    const handleClearFilters = (tipo) => {
        model.setCodigoFilter(null);
        model.setDescripcionFilter(null);
        model.setStockFilter(null);
        model.setEstadoFilter(null);
        model.setData([]);
        model.setDataKit([]);
        if(tipo===3){
            handleKit();
        }
        else{
            handleSearch(tipo);
        }
    };
    const handleClearFiltersSesion = () => {
        
        model.setFechaInicio(null);
        model.setFechaInicioF('');        
        model.setFechaFin(null);
        model.setFechaFinF('');
        handleLstSesion();
    };

    const handleClearUser = () => {
        model.setUsername('');
        model.setPassword('');
    };

    const handleCleanImport = () => {
        model.setLoggedIn(false);
        handleClearUser();
    };

    const handleCloseSnackbar = () => {
        model.setSnackbarOpen(false);
    };

    const handleCodigoChange = (event) => {
        model.setCodigoFilter(event.target.value);
    };

    const handleDescripcionChange = (event) => {
        model.setDescripcionFilter(event.target.value);
    };

    const handleStockChange = (event) => {
        model.setStockFilter(event.target.value);
    };

    const handleEstadoChange = (event) => {
        model.setEstadoFilter(event.target.value);
    };

    const handleDetail = (item) => {
        model.setSelectedItem(item);
    };
    
    const handleFechIniChange = (newDate) => {
        if (newDate) {
            const formattedDate = newDate.toLocaleDateString('en-CA');
            model.setFechaInicio(formattedDate);
            model.setFechaInicioF(`${formattedDate} 00:00:00`);
            console.log('Fecha inicio:', formattedDate);
            console.log('Fecha inicio filtro:', model.fechaInicioF);
        }
    };
    
    const handleFechFinChange = (newDate) => {
        if (newDate) {
            const formattedDate = newDate.toLocaleDateString('en-CA');
            model.setFechaFin(formattedDate);
            model.setFechaFinF(`${formattedDate} 23:59:59`);
            console.log('Fecha fin:', formattedDate);
            console.log('Fecha fin filtro:', model.fechaFinF);
        }
    };

    const snackBarFuntion = (message, type) => {
        model.setSnackbarMessage(message);
        model.setSnackbarSeverity(type);
    }
// Maneja el clic en el fondo para evitar que se cierre el modal
    const handleBackdropClick = (event) => {
        event.stopPropagation();
    };
    const leerArchivoExcel = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const data = event.target.result;
                    const workbook = XLSX.read(data, { type: 'binary' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    let jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1, range: 1 });
                    // Filtrar filas vacías
                    jsonData = jsonData.filter(row => row.some(cell => cell !== null && cell !== undefined && cell !== ''));
                    resolve(jsonData);
                };
                reader.readAsBinaryString(file);
            } catch (error) {
                alert('Error al importar excel:', error);
                reject(error);
            }
        });
    };

    const handleImportData = async (p_funcion) => {
        model.setImporting(true);
        try {
            const fileInput = document.getElementById('fileInput');
            const file = fileInput.files[0]; // Obtén el archivo seleccionado
            const data = await leerArchivoExcel(file);

            const responseIp = await axios.get('https://api.ipify.org?format=json');
            const usuario = localStorage.getItem('username');
            const dataToSend = {
                data: data,
                ip: responseIp.data.ip,
                userAudit: usuario,
                tipo: 2,
                funcion:p_funcion
            };
            const response = await fetch(`${API_URL}importData`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataToSend)
            });

            if (response.ok) {
                fileInput.value = '';
                snackBarFuntion('Datos importados correctamente','success');
                handleCleanImport();
            } else {
                
                snackBarFuntion('Error al importar datos: ' + response.statusText,'error');
                alert('Error al importar datos: ', response.statusText);
                console.error('Error al importar datos: ', response.statusText);
            }
        } catch (error) {
            snackBarFuntion('Error al importar datos: ' + error.message,'error');
            alert('Error al importar datos:', error.message);
            console.error('Error al importar datos:', error.message);
        } finally {
            model.setImporting(false);
            model.setSnackbarOpen(true);
        }
    };

    const handleSearch = async (tipo) => {
        try {
            const url = new URL(`${API_URL}consultaStock`);
            url.searchParams.append('tipo', tipo);
            if (model.codigoFilter != null && model.codigoFilter.length > 0) {
                url.searchParams.append('codigo', model.codigoFilter);
            }
            if (model.descripcionFilter != null && model.descripcionFilter.length > 0) {
                url.searchParams.append('descripcion', model.descripcionFilter);
            }
            if (model.estadoFilter != null && model.estadoFilter.length > 0) {
                url.searchParams.append('estado', model.estadoFilter);
            }
            if (model.stockFilter != null && model.stockFilter.toString().length > 0) {
                url.searchParams.append('stockDisponible', model.stockFilter);
            }
            const response = await fetch(url);
            const jsonData = await response.json();
            model.setData(jsonData);
        } catch (error) {
            alert('Error al obtener los datos:', error);
            console.error('Error al obtener los datos:', error);
        }
    };

    const handleSearchRepuestoKit = async () => {
        try {
            const url = new URL(`${API_URL}consultaStock`);
            url.searchParams.append('tipo', 2);
            if (model.searchPartnumber != null && model.searchPartnumber.length > 0) {
                url.searchParams.append('codigo', model.searchPartnumber);
            }
            const response = await fetch(url);
            const jsonData = await response.json();
            model.setSearchResults(jsonData);
        } catch (error) {
            alert('Error al obtener los datos:', error);
            console.error('Error al obtener los datos:', error);
        }
    };

    const handleLstSesion = async () => {
        try {
            const url = new URL(`${API_URL}lstSesion`);
            if (model.fechaInicioF != null && model.fechaInicioF.length > 0) {
                url.searchParams.append('fInicio', model.fechaInicioF);
            }
            if (model.fechaFinF != null && model.fechaFinF.length > 0) {
                url.searchParams.append('fFin', model.fechaFinF);
            }
            const response = await fetch(url);
            const jsonData = await response.json();
            model.setDataSesion(jsonData);
        } catch (error) {
            alert('Error al obtener los datos:', error);
            console.error('Error al obtener los datos:', error);
        }
    };

    const handleListarEstado = async () => {
        try {
            const url = new URL(`${API_URL}listEstado`);
            const response = await fetch(url);
            const jsonData = await response.json();
            const filteredData = jsonData
                .filter(item => item.estado && typeof item.estado === 'string' && item.estado.trim() !== '')
                .map(item => ({ value: item.estado, name: item.estado }));
            model.setLstEstado(filteredData);
        } catch (error) {
            alert('Error al obtener los datos:', error);
            console.error('Error al obtener los datos:', error);
        }
    };
    const handleLogoff = async () => {
        localStorage.removeItem('userId');
        localStorage.removeItem('username');
        localStorage.removeItem('profile');
        snackBarFuntion('Sesión cerrada correctamenta','success');
        model.setLoggedIn(false);
        model.setOpenLoginModal(true);
        navigate('/login');
    };

    const handleLogin = async () => {
        try {            
            const responseIp = await axios.get('https://api.ipify.org?format=json');
            const response = await fetch(`${API_URL}authenticate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ usuario: model.username, pass: model.password, ip: responseIp.data.ip, tipo: 1 })
            });

            if (!response.ok) {
                if (response.status === 401) {               
                    snackBarFuntion('Credenciales inválidas','error');
                } else {               
                    snackBarFuntion('Error del servidor','error');
                }
                return;
            }

            const data = await response.json();
            if (data.success) {
                localStorage.setItem('userId', data.user.id_user);
                localStorage.setItem('username', data.user.usuario);
                localStorage.setItem('profile', data.user.perfil);
                snackBarFuntion('Inicio de sesión correcto','success');
                model.setLoggedIn(true);
                model.setOpenLoginModal(true);
                model.setUsername('');
                model.setPassword('');
                if (data.user.perfil == 3) {
                    navigate('/consulta-repuesto');
                } else {
                    navigate('/consulta-stock');
                }
            } else {
                snackBarFuntion('Credenciales inválidas','error');
            }
        } catch (error) {
            console.error('Error al autenticar:', error);                
            snackBarFuntion('Credenciales inválidas','error');
        } finally {
            model.setSnackbarOpen(true);
        }
    };

    const handleHistCarga = async () => {
        try {
            const url = new URL(`${API_URL}consultaHistCarga`);
            // Llama al endpoint de la API sin parámetros
            const response = await fetch(url);
            if (!response.ok) {
                snackBarFuntion('Error al consultar datos: ' + response.statusText,'error');
                alert('Error al consultar datos: ', response.statusText);
                console.error('Error al consultar datos: ', response.statusText);
            }
            const jsonData = await response.json();
            model.setDataDate(jsonData);
        } catch (error) {
            alert('Error al obtener los datos:', error);
            console.error('Error al obtener los datos:', error);
        }
    };

    // Función para ejecutar el guardado del kit
    const handleGuardarKit = async () => {
        try {
            // Llamada al endpoint para crear el kit
            const dataToSend = {
                nombre: model.kitNombre,
                descripcion: model.kitDescripcion,
                repuestos: model.kitPartnumbers
            };
            const response = await fetch(`${API_URL}kitDetalle`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataToSend)
            });

            if (response.ok) {
                model.setKitNombre('');
                model.setKitDescripcion('');
                model.setKitPartnumbers([]);                
                snackBarFuntion('Kit registrado correctamente','success');
                model.setOpenAddKitModal(false);
            } else {                
                snackBarFuntion('Error al registrar kit: ' + response.statusText,'error');
                alert('Error al registrar kit: ', response.statusText);
                console.error('Error al registrar kit: ', response.statusText);
            }
        } catch (error) {
            console.error('Error al guardar el kit:', error);
        }
    };

    const handleKit = async () => {
        try {
            const url = new URL(`${API_URL}listKit`);
            if (model.codigoFilter != null && model.codigoFilter.length > 0) {
                url.searchParams.append('name', model.codigoFilter);
            }
            const response = await fetch(url);
            const jsonData = await response.json();
            model.setDataKit(jsonData);
        } catch (error) {
            alert('Error al obtener los datos:', error);
            console.error('Error al obtener los datos:', error);
        }
    };

    const handleDetailKit = async(item) => {
        try {
            const url = new URL(`${API_URL}listKitDetalle`);
            url.searchParams.append('kitId', item.id_kit_repuestos);
            const response = await fetch(url);
            const jsonData = await response.json();
            model.setDataKitTmp(item);
            model.setDataKitDetalle(jsonData);
        } catch (error) {
            alert('Error al obtener los datos:', error);
            console.error('Error al obtener los datos:', error);
        }
    };

    const handleDetailKitRep = async(item) => {
        try {
            model.setSelectedItem(item);
            const url = new URL(`${API_URL}listKitRespDet`);
            url.searchParams.append('pnRepuesto', item.partnumber);
            const response = await fetch(url);
            const jsonData = await response.json();
            model.setDataKitDetRep(jsonData);
        } catch (error) {
            alert('Error al obtener los datos:', error);
            console.error('Error al obtener los datos:', error);
        }
    };

    // Función para agregar un partnumber
    const handleAddPartnumber = (partnumber) => {
        model.setKitPartnumbers([...model.kitPartnumbers, partnumber]);
        model.setOpenPartnumberSearchModal(false); // Cierra el modal de búsqueda
    };

    const handleOpenPartnumberSearchModal = () => {
        model.setOpenPartnumberSearchModal(true);
        model.setSearchResults([]);
        model.setSearchPartnumber('');
    };

    // Función para eliminar un partnumber de la lista
    const handleRemovePartnumber = (partnumber) => {
        model.setKitPartnumbers(model.kitPartnumbers.filter(pn => pn !== partnumber));
    };

    return {        
        handleClearFilters,
        handleCleanImport,
        handleCodigoChange,
        handleDescripcionChange,
        handleStockChange,
        handleEstadoChange,
        handleDetail,
        handleCloseSnackbar,
        snackBarFuntion,
        handleSearch,
        handleListarEstado,
        handleImportData,
        handleLogin,
        handleLogoff,
        handleBackdropClick,
        handleHistCarga,
        handleLstSesion,
        handleFechIniChange,
        handleFechFinChange,
        handleClearFiltersSesion,
        handleGuardarKit,
        handleAddPartnumber,
        handleRemovePartnumber,
        handleSearchRepuestoKit,
        handleOpenPartnumberSearchModal,
        handleKit,
        handleDetailKit,
        handleDetailKitRep,
    };
};

export default Controller;
