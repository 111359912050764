import React from 'react';

const GestionUsuariosView = ({ model, controller }) => {
    return (
        <div>
            <h1>Gestión de Usuarios</h1>
            {/* Aquí puedes añadir el contenido específico de la gestión de usuarios */}
        </div>
    );
};

export default GestionUsuariosView;
