import React from 'react';
import { Container, Typography, TextField, Button, Paper, Snackbar, Alert, Backdrop, CircularProgress } from '@mui/material';
import './view.css'; // Import the CSS file

const LoginView = ({ model, controller }) => {
    return (
        <Container container maxWidth="sm" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',flexWrap: 'wrap', alignContent: 'center', height: '100vh' }}>
            <img src={process.env.PUBLIC_URL + '/assets/logo.png'} style={{ maxWidth: '35%', maxHeight: '35%', width: 'auto', height: 'auto', marginBottom:'5vh'}} alt=''/>
            <Paper style={{ padding: '20px', textAlign: 'center'}}>
                <Typography variant="h4" gutterBottom>Iniciar Sesión</Typography>
                <TextField
                    label="Nombre de usuario"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={model.username}
                    onChange={(e) => model.setUsername(e.target.value)}
                />
                <TextField
                    label="Contraseña"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={model.password}
                    onChange={(e) => model.setPassword(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => controller.handleLogin()}
                    style={{ marginTop: '20px' }}
                    fullWidth>
                    Iniciar Sesión
                </Button>
            </Paper>
            <Snackbar open={model.snackbarOpen} autoHideDuration={6000} onClose={controller.handleCloseSnackbar}>
                <Alert onClose={controller.handleCloseSnackbar} severity={model.snackbarSeverity} sx={{ width: '100%' }}>
                    {model.snackbarMessage}
                </Alert>
            </Snackbar>
            <Backdrop open={model.importing} style={{ zIndex: 1300, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            
        </Container>
    );
};

export default LoginView;
