import React, { useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ConstructionIcon from '@mui/icons-material/Construction';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import { Link as RouterLink } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const drawerWidth = 240;

// Styled AppBar with color adjustments
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: '#031560', // Set AppBar background color
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

// Styled Drawer with color adjustments
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: '#031560', // Set Drawer background color
    color: '#FFFFFF', // Set Drawer text color
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const theme = createTheme({
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: '#FFFFFF', // Set ListItemButton text color
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#FFFFFF', // Set ListItemIcon color
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: '#FFFFFF', // Set ListItemText primary color
        },
      },
    },
  },
});

export default function NavMenu({ userRole }) {
  const [open, setOpen] = React.useState(false);
  const [currentRole, setCurrentRole] = React.useState(userRole);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  useEffect(() => {
    const storedRole = parseInt(localStorage.getItem('profile'));
    if (storedRole !== currentRole) {
      setCurrentRole(storedRole);
    }
  }, [userRole]);
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer} sx={{ color: 'white' }}>
              {open ? <ChevronLeftIcon backgroundColor/> : <ChevronRightIcon />}
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {(userRole == 1 || userRole == 2 || userRole == 4) && (
              <>
            <RouterLink to="/consulta-stock" style={{ textDecoration: 'none' }}>
              <ListItemButton>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Consulta Stock" />
              </ListItemButton>
            </RouterLink>
              </>
            )}
            {userRole == 1 && (
              <>
                <RouterLink to="/gestion-login" style={{ textDecoration: 'none' }}>
                  <ListItemButton>
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Gestión de sesión" />
                  </ListItemButton>
                </RouterLink>
              </>
            )}
            {(userRole == 1 || userRole == 3 || userRole == 4) && (
              <>
                <RouterLink to="/consulta-repuesto" style={{ textDecoration: 'none' }}>
                  <ListItemButton>
                    <ListItemIcon>
                      <ConstructionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Consulta Repuestos" />
                  </ListItemButton>
                </RouterLink>
              </>
            )}
            {(userRole == 1) && (
              <>
                <RouterLink to="/kit-repuesto" style={{ textDecoration: 'none' }}>
                  <ListItemButton>
                    <ListItemIcon>
                      <HomeRepairServiceIcon />
                    </ListItemIcon>
                    <ListItemText primary="Kit Repuestos" />
                  </ListItemButton>
                </RouterLink>
              </>
            )}
          </List>
        </Drawer>
      </Box>
    </ThemeProvider>
  );
}

                {/*<RouterLink to="/gestion-usuarios" style={{ textDecoration: 'none' }}>
                  <ListItemButton>
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Gestión de Usuarios" />
                  </ListItemButton>
                </RouterLink>*/}