import React from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login'; // Asegúrate de que el ícono esté disponible

const HeaderComponent = ({ title, controller }) => {
  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item xs={12} sm={2} md={2} lg={3}>
        <img 
          src={process.env.PUBLIC_URL + '/assets/logo.png'} 
          style={{ maxWidth: '35%', maxHeight: '35%', width: 'auto', height: 'auto' }} 
          alt='' 
        />
      </Grid>
      <Grid item xs={12} sm={8} md={8} lg={6}>
        <Typography 
          variant="h2" 
          align="center" 
          gutterBottom 
          style={{ color: '#031560' }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={2} md={2} lg={3} style={{ textAlign: 'right' }}>
        {(parseInt(localStorage.getItem('userId')) > 0 || !parseInt(localStorage.getItem('userId'))) && (
          <IconButton 
            onClick={() => controller.handleLogoff()} 
            style={{ borderRadius: '50%', color: 'white', background: '#031560', width: '48px', height: '48px' }} 
            alt=""
          >
            <LoginIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default HeaderComponent;
