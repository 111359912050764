import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Button, Modal, Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination,
    TextField, IconButton, CircularProgress, Snackbar, Alert, Backdrop } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import HeaderComponent from '../components/HeaderComponent';
import './view.css'; // Import the CSS file

// Función para formatear la fecha
const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son 0-11
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
};

const DataTable = ({ data, handleDetail }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nombre kit</TableCell>
                        <TableCell>Descripción</TableCell>
                        <TableCell>Acción</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => (
                        <TableRow key={item.id_kit_repuestos}>
                            <TableCell>{item.nombre}</TableCell>
                            <TableCell>{item.kit_descripcion}</TableCell>
                            <TableCell><Button variant="outlined" onClick={() => handleDetail(item)}>Ver Detalle</Button></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                onRowsPerPageChange={(e) => {
                    setRowsPerPage(parseInt(e.target.value, 10));
                    setPage(0);
                }}
                labelRowsPerPage="Filas por página:"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                selectProps={{
                    native: true,
                }}
            />
        </TableContainer>
    );
};
// Vista
const ManagerKitRespuestos = ({ model, controller }) => {
    useEffect(() => {
        controller.handleClearFilters(3);
        if (!model.dataDate) {
            controller.handleHistCarga();
        }
        if (!model.dataKit.length) {
            controller.handleKit();
        }
    }, []);
    
    return (
        <Container>
            <HeaderComponent title="Kit Repuestos" controller={controller} />
            <Grid container spacing={2}>
                {/* Campos de filtro */}
                <Grid container item xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                        label="Nombre de kit"
                        value={model.codigoFilter}
                        onChange={controller.handleCodigoChange}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                {/*<Grid item xs={12} sm={6} md={4} lg={3} style={{ paddingTop: 0 }}>
                    <Filter
                        label="Estado"
                        options={[{ value: '', name: 'Seleccionar' }, ...model.lstEstado]}
                        selected={model.estadoFilter}
                        handleChange={controller.handleEstadoChange}
                    />
                </Grid>*/}
                <Grid item xs={3} sm={3} md={3} lg={3} style={{ display: 'flex' }}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <IconButton onClick={() => controller.handleKit()} style={{ borderRadius: '50%', color: 'white', background: '#031560', width: '48px', height: '48px' }}>
                            <SearchIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <IconButton onClick={() => controller.handleClearFilters(3)} style={{ borderRadius: '50%', color: 'white', background: '#031560', width: '48px', height: '48px' }}>
                            <HighlightOffIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <IconButton onClick={() => model.setOpenAddKitModal(true)} style={{ borderRadius: '50%', color: 'white', background: '#031560', width: '48px', height: '48px' }}>
                            <AddCircleOutlineIcon />
                        </IconButton>
                    </Grid>
                </Grid>                
                </Grid>
            <Snackbar open={model.snackbarOpen} autoHideDuration={6000} onClose={controller.handleCloseSnackbar}>
                <Alert onClose={controller.handleCloseSnackbar} severity={model.snackbarSeverity} sx={{ width: '100%' }}>
                    {model.snackbarMessage}
                </Alert>
            </Snackbar>
            <Backdrop open={model.importing} style={{ zIndex: 1300, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            </Grid>
            {/* Tabla de datos */}
            <DataTable data={model.dataKit} handleDetail={controller.handleDetailKit} />
            {model.dataDate && model.dataDate.length > 0 && (
                <Grid item xs={12} sm={2} md={2} lg={3} style={{ textAlign: 'right', marginTop: '5px' , color: '#031560' }}>
                    <Typography variant="h6">Fecha de Carga: {formatDate(model.dataDate[0].fechaCarga)}</Typography>                  
                </Grid>
            )}
            {/* Detalles del producto seleccionado */}
            <Modal open={model.dataKitDetalle !== null && model.dataKitDetalle.length > 0} onClose={() => model.setDataKitDetalle(null)}>
                <Paper className="modal-paper">
                    <Grid container justifyContent="space-between" alignItems="center" className="modal-header">
                        <Grid item xs={12} sm={2} md={2} lg={3}>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={6}>
                            <Typography variant="h5" align="center" gutterBottom>Detalles del Kit</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={3} style={{ textAlign: 'right' }}>
                            <IconButton onClick={() => model.setDataKitDetalle(null)}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {model.dataKitDetalle && (
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle1"><strong>Nombre de kit:</strong> {model.dataKitTmp.nombre}</Typography>
                                <Typography variant="subtitle1"><strong>Descripción:</strong> {model.dataKitTmp.kit_descripcion}</Typography>                           
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                    <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>PartNumber</TableCell>
                                            <TableCell>Marca</TableCell>
                                            <TableCell>Modelo</TableCell>
                                            <TableCell>Tipo</TableCell>
                                            <TableCell>Stock</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {model.dataKitDetalle.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{item.id_partnumber}</TableCell>
                                                <TableCell>{item.marca}</TableCell>
                                                <TableCell>{item.modelo}</TableCell>
                                                <TableCell>{item.tipo}</TableCell>
                                                <TableCell>{item.stockDsp}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
            </Modal>

            {/* Modal para agregar kit */}
            <Modal open={model.openAddKitModal} onClose={() => model.setOpenAddKitModal(false)}>
                <Paper className="modal-paper">
                    <Typography variant="h5" align="center" gutterBottom>Agregar Kit</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Nombre del Kit"
                                value={model.kitNombre}
                                onChange={(e) => model.setKitNombre(e.target.value)}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Descripción del Kit"
                                value={model.kitDescripcion}
                                onChange={(e) => model.setKitDescripcion(e.target.value)}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined" onClick={() => controller.handleOpenPartnumberSearchModal()}>Agregar Partnumber</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>PartNumber</TableCell>
                                            <TableCell>Acción</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {model.kitPartnumbers.map((pn, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{pn}</TableCell>
                                                <TableCell>
                                                    <Button variant="outlined" color="secondary" onClick={() => controller.handleRemovePartnumber(pn)}>Eliminar</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'right' }}>
                            <Button variant="contained" color="primary" onClick={() => controller.handleGuardarKit()}>Guardar</Button>
                            <Button variant="outlined" color="secondary" onClick={() => model.setOpenAddKitModal(false)}>Cancelar</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>

            {/* Submodal para buscar partnumber */}
            <Modal open={model.openPartnumberSearchModal} onClose={() => model.setOpenPartnumberSearchModal(false)}>
                <Paper className="modal-paper">
                    <Typography variant="h6" align="center" gutterBottom>Buscar Partnumber</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Buscar Partnumber"
                                value={model.searchPartnumber}
                                onChange={(e) => model.setSearchPartnumber(e.target.value)}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={() => controller.handleSearchRepuestoKit()}>Buscar</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>PartNumber</TableCell>
                                            <TableCell>tipo</TableCell>
                                            <TableCell>Acción</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {model.searchResults.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{item.partnumber}</TableCell>
                                                <TableCell>{item.tipo}</TableCell>
                                                <TableCell>
                                                    <Button variant="outlined" color="primary" onClick={() => controller.handleAddPartnumber(item.partnumber)}>Agregar</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
        </Container>
    );
};

export default ManagerKitRespuestos;