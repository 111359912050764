import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Button, Modal, Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination,
    TextField, FormControl, InputLabel, Select, MenuItem, IconButton, CircularProgress, Snackbar, Alert, Backdrop, Card, CardContent } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import LoginIcon from '@mui/icons-material/Login';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloseIcon from '@mui/icons-material/Close';
import HeaderComponent from '../components/HeaderComponent';
import './view.css'; // Import the CSS file


const formatCurrency = (value) => {
    const numberValue = value != null ? Number(value) : 0;
    return numberValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

// Función para formatear la fecha
const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son 0-11
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year}`;
};

const Filter = ({ label, options, selected, handleChange }) => {
    return (
        <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>{label}</InputLabel>
            <Select
                value={selected}
                onChange={handleChange}
                label={label}
            >
                {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const DataTable = ({ data, handleDetail }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>PartNumber</TableCell>
                        <TableCell>Descripción</TableCell>
                        <TableCell>Stock disponible</TableCell>
                        <TableCell>Costo Unitario</TableCell>
                        <TableCell>Acción</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => (
                        <TableRow key={item.partnumber}>
                            <TableCell>{item.partnumber}</TableCell>
                            <TableCell>{item.descripcion}</TableCell>
                            <TableCell>{item.stockDsp}</TableCell>
                            <TableCell>{item.precunit != null ? formatCurrency(item.precunit) : formatCurrency(0)}</TableCell>
                            <TableCell><Button variant="outlined" onClick={() => handleDetail(item)}>Ver Detalle</Button></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                onRowsPerPageChange={(e) => {
                    setRowsPerPage(parseInt(e.target.value, 10));
                    setPage(0);
                }}
                labelRowsPerPage="Filas por página:"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                selectProps={{
                    native: true,
                }}
            />
        </TableContainer>
    );
};
// Vista
const View = ({ model, controller }) => {
    useEffect(() => {
        controller.handleClearFilters(2);
        if (!model.dataDate) {
            controller.handleHistCarga();
        }
        if (!model.lstEstado.length) {
            controller.handleListarEstado();
        }
        if (!model.data.length) {
            controller.handleSearch(2);
        }        
    }, []);
    return (
        <Container>
            <HeaderComponent title="Almacen Repuestos" controller={controller} />
            <Grid container spacing={2}>
                {/* Campos de filtro */}
                <Grid container item xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                        label="PartNumber"
                        value={model.codigoFilter}
                        onChange={controller.handleCodigoChange}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                        label="Descripción"
                        value={model.descripcionFilter}
                        onChange={controller.handleDescripcionChange}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                {/*<Grid item xs={12} sm={6} md={4} lg={3} style={{ paddingTop: 0 }}>
                    <Filter
                        label="Estado"
                        options={[{ value: '', name: 'Seleccionar' }, ...model.lstEstado]}
                        selected={model.estadoFilter}
                        handleChange={controller.handleEstadoChange}
                    />
                </Grid>*/}
                <Grid item xs={12} sm={6} md={4} lg={3} style={{ paddingTop: 0 }}>
                    <Filter
                        label="Stock"
                        options={[{ value: '', name: 'Seleccionar' }, { value: 2, name: 'Sin Stock' }, { value: 1, name: 'Con Stock' }]}
                        selected={model.stockFilter}
                        handleChange={controller.handleStockChange}
                    />
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} style={{ display: 'flex' }}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <IconButton onClick={() => controller.handleSearch(2)} style={{ borderRadius: '50%', color: 'white', background: '#031560', width: '48px', height: '48px' }}>
                            <SearchIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <IconButton onClick={() => controller.handleClearFilters(2)} style={{ borderRadius: '50%', color: 'white', background: '#031560', width: '48px', height: '48px' }}>
                            <HighlightOffIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                </Grid>
                {/* Botones de búsqueda y limpieza de filtros */}
                
                <Grid container item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: '5px',paddingBottom: '5px' }}>
                {/* Sección de importación de datos */}
                {parseInt(localStorage.getItem('profile'))===1 ? (
                <Grid item xs={9} sm={9} md={9} lg={9} style={{ display: 'flex' }}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <input type="file" id="fileInput" />
                        <Button variant="contained" onClick={() => controller.handleImportData(2)} disabled={model.importing} style={{ color: 'white', background: '#031560'}}>
                            {model.importing ? <CircularProgress size={24} /> : 'Importar desde Excel'}
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <></>
            )}
            </Grid>
            <Snackbar open={model.snackbarOpen} autoHideDuration={6000} onClose={controller.handleCloseSnackbar}>
                <Alert onClose={controller.handleCloseSnackbar} severity={model.snackbarSeverity} sx={{ width: '100%' }}>
                    {model.snackbarMessage}
                </Alert>
            </Snackbar>
            <Backdrop open={model.importing} style={{ zIndex: 1300, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            </Grid>
            {/* Tabla de datos */}
            <DataTable data={model.data} handleDetail={controller.handleDetailKitRep} />
            {/*{model.dataDate && (                
                <Grid item xs={12} sm={2} md={2} lg={3} style={{ textAlign: 'right' }}>
                    <h2>Fecha de Carga:</h2>
                    <pre>{formatDate(model.dataDate[0].fechaCarga)}</pre>
                </Grid>*/}
                {model.dataDate && model.dataDate.length > 0 && (
                    <Grid item xs={12} sm={2} md={2} lg={3} style={{ textAlign: 'right', marginTop: '5px' , color: '#031560' }}>
                        <Typography variant="h6">Fecha de Carga: {formatDate(model.dataDate[0].fechaCarga)}</Typography>                  
                    </Grid>
                )}
            {/*)}*/}
            {/* Detalles del producto seleccionado */}
            <Modal open={model.selectedItem !== null} onClose={() => model.setSelectedItem(null)}>
                <Paper className="modal-paper">
                    <Grid container justifyContent="space-between" alignItems="center" className="modal-header">
                        <Grid item xs={12} sm={2} md={2} lg={3}>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={6}>
                            <Typography variant="h5" align="center" gutterBottom>Detalles del Producto</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={3} style={{ textAlign: 'right' }}>
                            <IconButton onClick={() => model.setSelectedItem(null)}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {model.selectedItem && (
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle1"><strong>PartNumber:</strong> {model.selectedItem.partnumber}</Typography>
                                <Typography variant="subtitle1"><strong>Tipo:</strong> {model.selectedItem.tipo}</Typography>
                                <Typography variant="subtitle1"><strong>Descripción:</strong> {model.selectedItem.descripcion}</Typography>                           
                                <Grid container>
                                    <Grid item xs={6} sm={6}>
                                        <Typography variant="subtitle1"><strong>Stock Disponible:</strong> {model.selectedItem.stockDsp != null ? model.selectedItem.stockDsp : 0}</Typography>
                                        <Typography variant="subtitle1">
                                            <strong>Costo Unitario:</strong> {model.selectedItem.precunit != null ? formatCurrency(model.selectedItem.precunit) : formatCurrency(0)}
                                        </Typography> 
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <Typography variant="subtitle1"><strong>Marca:</strong> {model.selectedItem.marca != null ? model.selectedItem.marca : '-'}</Typography>
                                        <Typography variant="subtitle1"><strong>Modelo:</strong> {model.selectedItem.modelo != null ? model.selectedItem.modelo : '-'}</Typography>
                                    </Grid>
                                    {model.dataKitDetRep.length>0 && (
                                        <Grid item xs={12} sm={12}>
                                        <Typography variant="h6">{model.dataKitDetRep[0].nombre} - {model.dataKitDetRep[0].kit_descripcion}</Typography>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>PartNumber</TableCell>
                                                        <TableCell>Marca</TableCell>
                                                        <TableCell>Modelo</TableCell>
                                                        <TableCell>Tipo</TableCell>
                                                        <TableCell>Stock Disponible</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                        {model.dataKitDetRep.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{item.id_partnumber}</TableCell>
                                                <TableCell>{item.marca}</TableCell>
                                                <TableCell>{item.modelo}</TableCell>
                                                <TableCell>{item.tipo}</TableCell>
                                                <TableCell>{item.stockDsp}</TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
            </Modal>
        </Container>
    );
};

export default View;