import { useState } from 'react';

// Modelo
const Model = () => {
    const [codigoFilter, setCodigoFilter] = useState(''); // Filtro de código
    const [descripcionFilter, setDescripcionFilter] = useState(''); // Filtro de descripción
    const [estadoFilter, setEstadoFilter] = useState(''); // Filtro de estado
    const [stockFilter, setStockFilter] = useState(''); // Filtro de stock
    const [data, setData] = useState([]); // Conjunto de datos
    const [dataSesion, setDataSesion] = useState([]); // Conjunto de datos
    const [lstEstado, setLstEstado] = useState([]); // Conjunto de datos
    const [selectedItem, setSelectedItem] = useState(null); // Elemento seleccionado
    const [loggedIn, setLoggedIn] = useState(false); // Estado de inicio de sesión
    const [openLoginModal, setOpenLoginModal] = useState(false); // Estado del modal de inicio de sesión
    const [username, setUsername] = useState(''); // Nombre de usuario
    const [password, setPassword] = useState(''); // Contraseña
    const [importedMessage, setImportedMessage] = useState(''); // Mensaje de importación
    const [importing, setImporting] = useState(false); // Estado de importación
    //const [loading, setLoading] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const [dataDate, setDataDate] = useState(null);
    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);
    const [fechaInicioF, setFechaInicioF] = useState('');
    const [fechaFinF, setFechaFinF] = useState('');
    
    const [openAddKitModal, setOpenAddKitModal] = useState(false); // Controla el modal de agregar kit
    const [kitNombre, setKitNombre] = useState('');
    const [kitDescripcion, setKitDescripcion] = useState('');
    const [kitPartnumbers, setKitPartnumbers] = useState([]); // Partnumbers agregados al kit
    const [openPartnumberSearchModal, setOpenPartnumberSearchModal] = useState(false); // Controla el submodal para buscar partnumber
    const [searchPartnumber, setSearchPartnumber] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [dataKit, setDataKit] = useState([]); // Conjunto de datos kit
    const [dataKitTmp, setDataKitTmp] = useState([]); // Conjunto de datos kit
    const [dataKitDetalle, setDataKitDetalle] = useState([]); // Conjunto de datos kit detalle
    const [dataKitDetRep, setDataKitDetRep] = useState([]);
    
    return {
        codigoFilter,
        setCodigoFilter,
        descripcionFilter,
        setDescripcionFilter,
        estadoFilter,
        setEstadoFilter,
        stockFilter,
        setStockFilter,
        data,
        setData,
        lstEstado,
        setLstEstado,
        selectedItem,
        setSelectedItem,
        loggedIn,
        setLoggedIn,
        openLoginModal,
        setOpenLoginModal,
        username,
        setUsername,
        password,
        setPassword,
        importedMessage,
        setImportedMessage,
        importing,
        setImporting,
        snackbarOpen,
        setSnackbarOpen,
        snackbarMessage,
        setSnackbarMessage,
        snackbarSeverity,
        setSnackbarSeverity,
        dataDate,
        setDataDate,
        fechaInicio,
        setFechaInicio,
        fechaFin,
        setFechaFin,
        dataSesion,
        setDataSesion,
        fechaInicioF,
        setFechaInicioF,
        fechaFinF,
        setFechaFinF,
        openAddKitModal,
        setOpenAddKitModal,
        kitNombre,
        setKitNombre,
        kitDescripcion,
        setKitDescripcion,
        kitPartnumbers,
        setKitPartnumbers,
        openPartnumberSearchModal,
        setOpenPartnumberSearchModal,
        searchPartnumber,
        setSearchPartnumber,
        searchResults,
        setSearchResults,
        dataKit,
        setDataKit,
        dataKitDetalle,
        setDataKitDetalle,
        dataKitTmp,
        setDataKitTmp,
        dataKitDetRep,
        setDataKitDetRep,
    };
};

export default Model;
